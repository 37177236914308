<template>
	<div>
		<div class="mobx">
			<img src="https://qiniu-res.ylt123.com/4.jpg" ref="image" style="z-index: 10"
				:class="{ animated_image: isActive }" v-if="donShow && isOpen == 0" @click="donClick"
				@animationend="replaceImage" />
			<!-- <img src="https://qiniu-res.ylt123.com/1.646b9d64.gif" v-if="!donShow && isOpen == 0" /> -->
			<video :src="video1" :autoplay="false" v-show="isOpen == 1" ref="videoref1" @ended="onVideoEnded">
			</video>
			<video :src="video2" :autoplay="false" v-show="isOpen == 2" ref="videoref2" @ended="onVideoEnded">
			</video>
			<video :src="video3" :autoplay="false" v-show="isOpen == 3" ref="videoref3" @ended="onVideoEnded">
			</video>
			<!-- <div class="mobx_btn1" @click="autiofist"></div>
			<div class="mobx_btn2" @click="autiosecond"></div>
			<div class="mobx_btn3" @click="autiothird"></div> -->

			<div class="voice-btn-wrap" v-if="videoStatus == 'end'">
				<div class="voice-btn" @mousedown="voiceStart" @mouseup="voiceStop"></div>
				<div class="voice-text">按住说话</div>
			</div>
			<div class="mask" v-show="isOpen != 0">数字人由AI生成</div>
		</div>
	</div>

</template>

<script>
	// import video1 from '@/assets/audio/1.mp4'
	// import video2 from '@/assets/audio/2.mp4'
	// import video3 from '@/assets/audio/3.mp4'
	import {
		XfVoiceDictation
	} from "@muguilin/xf-voice-dictation";
	// let times = null;
	// 实例化迅飞语音听写（流式版）WebAPI https://gitee.com/muguilin/VoiceDictation
	let xfVoice = ''
	export default {
		name: "HelloWorld",
		props: {
			msg: String,
		},
		data() {
			return {
				video1: 'https://qiniu-res.ylt123.com/1.mp4',
				video2: 'https://qiniu-res.ylt123.com/2.mp4',
				video3: 'https://qiniu-res.ylt123.com/3.mp4',
				isOpen: '',
				donShow: true,
				isActive: false,
				videoStatus: '',
				voiceBusy: false

			};
		},
		mounted() {

			if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
				// 弹框获取麦克风
				navigator.mediaDevices.getUserMedia({
					audio: true
				}).then((stream) => {
					console.log(stream)
				});
			}
			let that = this
			xfVoice = new XfVoiceDictation({
				APPID: "462f8e90",
				APISecret: "ZmUzNDUwNzQyZWY1YTgwZjQ5MTFiOTIy",
				APIKey: "4f071f4269bba3ebd67dd09206f9c8e7",

				// webSocket请求地址 非必传参数，默认为：wss://iat-api.xfyun.cn/v2/iat
				// url: ''

				// 监听录音状态变化回调
				onWillStatusChange: function(oldStatus, newStatus) {
					// 可以在这里进行页面中一些交互逻辑处理：如：倒计时（语音听写只有60s）,录音的动画，按钮交互等！
					console.log("开始识别：", oldStatus, newStatus);
				},
				// 监听识别结果的变化回调
				onTextChange: function(text) {
					if (!that.voiceBusy) {
						that.voiceBusy = true
						// 可以在这里进行页面中一些交互逻辑处理：如将文本显示在页面中
						console.log("识别内容：", text);
						if (-1 != text.indexOf('广泛应用') || -1 != text.indexOf('广泛的应用') || -1 != text.indexOf(
								'广泛地应用')) {
							that.autiothird()
						}
						if (-1 != text.indexOf('如何看待')) {
							that.autiosecond()
						}
						setTimeout(() => {
							that.voiceBusy = false
						}, 1000);
						// 如果3秒钟内没有说话，就自动关闭（60s后也会自动关闭）
						// if (text) {
						//   clearTimeout(times);
						//   times = setTimeout(() => xfVoice.stop(), 3000);
						// }
					}
				},
			});
		},
		methods: {
			// 播放视频一
			autiofist() {
				console.log('播放视频一')
				this.videoStatus = 'star'
				this.isOpen = 1;
				this.$nextTick(() => {
					this.$refs.videoref1.play();
				})
			},
			// 播放视频二
			autiosecond() {
				// if (this.isOpen == 2) return;
				console.log('播放视频二')
				this.videoStatus = 'star'
				this.isOpen = 2;
				this.$nextTick(() => {
					this.$refs.videoref2.play();
				})
			},
			// 播放视频三
			autiothird() {
				// if (this.isOpen == 3) return;
				console.log('播放视频三')
				this.videoStatus = 'star'
				this.isOpen = 3;
				this.$nextTick(() => {
					this.$refs.videoref3.play();
				})
			},
			replaceImage() {
				// 动画结束后，更新图片的src属性
				this.donShow = false;
				this.autiofist()
			},
			donClick() {
				this.isActive = true;
			},
			// 监听视频播放完成，关闭视频
			onVideoEnded() {
				console.log("播放完成");

				this.videoStatus = 'end'
				// this.isOpen = 0;
			},
			voiceStop() {
				xfVoice.stop();
			},
			voiceStart() {
				xfVoice.start();
			},
			mouseup() {
				setTimeout(() => {
					if (this.isOpen == 1) {
						this.autiosecond()
					} else if (this.isOpen == 2) {
						this.autiothird()
					}
				}, 1000);
			}
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.animated_image {
		animation: fadeOut 2s forwards;
	}

	@keyframes fadeOut {
		from {
			opacity: 1;
			transform: scale(1);
		}

		to {
			opacity: 1;
			transform: scale(4);
		}
	}

	.mobx {
		width: 100%;
		height: 100vh;
		position: relative;
		overflow: hidden;
	}

	.mobx img {
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
	}

	.mobx_btn1 {
		width: 300px;
		height: 300px;
		position: absolute;
		top: 50%;
		left: 11%;
		/* background: rgba(155, 0, 155, 0.5); */
		cursor: pointer;
		z-index: 3;
	}

	.mobx_btn2 {
		width: 300px;
		height: 300px;
		position: absolute;
		top: 5%;
		right: 42%;
		/* background: rgba(155, 0, 155, 0.5); */
		cursor: pointer;
		z-index: 3;
	}

	.mobx_btn3 {
		width: 500px;
		height: 300px;
		position: absolute;
		top: 54%;
		right: 31%;
		/* background: rgba(155, 0, 155, 0.5); */
		cursor: pointer;
		z-index: 3;
	}

	.mobx video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 4;
	}

	.voice-btn-wrap {
		position: fixed;
		bottom: 86px;
		right: 132px;
		z-index: 999;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.voice-text {
		margin-top: 12px;
		font-size: 18px;
		font-weight: bold;
		color: #ffffff;
	}

	.voice-btn {
		width: 118px;
		height: 118px;
		border-radius: 50%;
		/* background: #f00;
		opacity: 0.2; */
		background-image: url('https://qiniu-res.ylt123.com/22.png');
		background-size: 100% 100%;
	}

	.voice-btn:active {
		background-image: url('https://qiniu-res.ylt123.com/11.png');
		background-size: 100% 100%;
	}



	.mask {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 999;
		background-color: #000000;
		padding: 16px 32px;
		font-size: 18px;
		font-weight: bold;
		color: #ffffff;
	}
</style>